<template>
	<page>
		<module enable-flex>
			<m-search ref="search" @search="searchHandle">
				<m-search-item label="任务编号">
					<c-input name="task_number"></c-input>
				</m-search-item>
				<m-search-item label="订单号">
					<c-input name="order_number"></c-input>
				</m-search-item>
				<m-search-item label="任务状态">
					<c-checkbox-group name="state" :options="stateOptions" :value="[1]"></c-checkbox-group>
				</m-search-item>
				<m-search-item label="客户名称">
					<c-input name="customer_name"></c-input>
				</m-search-item>
				<m-search-item label="产品">
					<c-input name="product_name"></c-input>
				</m-search-item>
				<m-search-item label="客户货号">
					<c-input name="customer_product_code"></c-input>
				</m-search-item>
				<m-search-item label="派工时间" colspan="2">
					<c-datetime start-name="start_date" end-name="end_date" range></c-datetime>
				</m-search-item>
				<m-search-item label="派工人">
					<c-input name="create_user_name"></c-input>
				</m-search-item>
				<m-search-item label="负责人">
					<c-input name="person_responsible_name"></c-input>
				</m-search-item>
			</m-search>
			<m-operate>
				<c-button v-if="auth('b_production_task_print_template')" color="sys" @click="selectTemplate(2, '生产任务单')">生产单打印模板管理</c-button>
				<c-button v-if="auth('b_production_task_print_template')" color="sys" @click="selectTemplate(3, '流程卡')">流程卡打印模板管理</c-button>
			</m-operate>
			<c-table ref="table" height="grow" >
				<c-table-column
					label="任务编号"
					width="100"
					name="task_number"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.task_number == data.task_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>
				<c-table-column
					label="任务状态"
					width="80"
					name="state"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.task_number == data.task_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
					<template #default="{data}">
						<span v-if="data.state == 1" style="color:blue">待完成</span>
						<span v-if="data.state == 2" style="color:green">已完成</span>
						<span v-if="data.state == 3" style="color:#666">已取消</span>
					</template>
				</c-table-column>
				<c-table-column
					label="报工状态"
					width="80"
					name="state"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.task_number == data.task_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
					<template #default="{data}">
						<span v-if="data.work_count == 0" style="color:blue">待报工</span>
						<span v-if="data.work_count > 0 && data.work_count < data.product_count" style="color:brown">部分报工</span>
						<span v-if="data.work_count > 0 && data.work_count >= data.product_count" style="color:green">全部报工</span>
					</template>
				</c-table-column>
				<c-table-column
					label="关联订单"
					width="130"
					name="order_number"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.task_number == data.task_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>
				<c-table-column
					label="客户名称"
					width="100"
					name="customer_name"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.task_number == data.task_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>
				<c-table-column
					label="任务交期"
					width="80"
					name="schedule_date"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.task_number == data.task_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>
				
				<c-table-column
					label="任务数"
					width="60"
					name="product_count"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.task_number == data.task_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>
				
				<c-table-column
					label="报工数"
					width="60"
					name="work_count"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.task_number == data.task_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>
				
				<c-table-column
					label="合格数"
					width="60"
					name="qualified_count"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.task_number == data.task_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>
				
				<c-table-column
					label="不合格数"
					width="70"
					name="unqualified_count"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.task_number == data.task_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>
				
				<c-table-column
					label="产品"
					width="200"
					name="product_name"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.task_number == data.task_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
					<template #default="{data}">
						<span>{{data.product_name}}</span>
						<span v-if="data.product_code">【{{data.product_code}}】</span>
					</template>
				</c-table-column>

				<c-table-column
					label="颜色"
					width="150"
					name="product_spec"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.task_number == data.task_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
					<template #default="{data}">
						<span>{{data.product_spec}}</span>
					</template>
				</c-table-column>

				<c-table-column
					label="客户货号"
					name="customer_product_code"
					width="100"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.task_number == data.task_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
					<template #default="{data}">
						<span v-if="data.customer_product_code">{{data.customer_product_code}}</span>
						<span v-else>--</span>
					</template>
				</c-table-column>
				
				<c-table-column
					label="颜色"
					width="80"
					name="product_color"
					v-if="systemConfig.system_size_enable=='ON'"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.task_number == data.task_number && item.data.product_color == data.product_color){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>
				<c-table-column
					label="颜色"
					name="product_spec"
					width="60"
					v-if="systemConfig.system_size_enable=='ON'"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.task_number == data.task_number && item.data.product_color == data.product_color && item.data.product_spec == data.product_spec){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>
				<template v-for="(size, i) in sizeConfig">
					<c-table-column
						:label="String(i+1)"
						width="50"
						v-if="systemConfig.system_size_enable=='ON'"
					>
						<template #default="{data}">
							<span v-if="data[size]">{{data[size]}}</span>
							<span v-else>-</span>
						</template>
					</c-table-column>
				</template>
				
<!--				<c-table-column
					label="班组"
					width="100"
					name="team_name"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.task_number == data.task_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>-->
				
				<c-table-column
					label="负责人"
					width="100"
					name="person_responsible_user_realname"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.task_number == data.task_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>
				
<!--				<c-table-column
					label="机台"
					width="80"
					name="machine_name"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.task_number == data.task_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>-->
				
				<c-table-column
					label="计划开始时间"
					width="110"
					name="task_begin_date"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.task_number == data.task_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>
				
				<c-table-column
					label="计划结束时间"
					width="110"
					name="task_end_date"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.task_number == data.task_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>

				<c-table-column
					label="质检人"
					width="100"
					name="quality_user_realname"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.task_number == data.task_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>

				<c-table-column
					label="派工人"
					width="100"
					name="create_user_realname"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.task_number == data.task_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>
				
				<c-table-column
					label="派工时间"
					width="140"
					name="create_date"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.task_number == data.task_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>

				<c-table-column
					type="button"
					label="操作"
					width="320"
					button-limit="4"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.task_number == data.task_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
					<template v-slot="{data}">
						<c-table-button v-if="data.state == 1 && data.work_count < data.product_count && auth('b_production_task_work')" @click="workTask(data)">报工</c-table-button>
						<c-table-button v-if="data.state == 1 && data.work_count >= data.product_count && auth('b_production_task_finish')" @click="finishTask(data)">任务完成</c-table-button>
						<c-table-button v-if="(data.state == 1 || data.state == 2 )&& auth('b_production_task_print')"@click="printTask(2,data)">打印任务单</c-table-button>
						<c-table-button v-if="(data.state == 1 || data.state == 2 )&& auth('b_production_task_print')"@click="printTask(3,data)">打印流程卡</c-table-button>
						<c-table-button @click="workDetail(data)">详情</c-table-button>
						<c-table-button v-if="data.state == 1 && data.work_count ==0 && auth('b_production_task_edit')" @click="cancelTask(data)">取消</c-table-button>
						<!-- <c-table-button v-if="data.work_count == 0 && data.state == 1 && auth('b_production_task_edit')" @click="delTask(data)">删除</c-table-button> -->
					</template>
				</c-table-column>
			</c-table>
		</module>

		<!--报工 弹框-->
		<c-dialog ref="workTask" :title="'任务：'+this.task_number+' - 报工'" height="600" width="680" @resolve="submitWorkTask">
			<c-detail column="1">
			    <!-- <c-detail-item label="产品名称">
			         {{this.product_name}}
			    </c-detail-item> -->
<!--			    <c-detail-item label="产品编码">
			        {{this.product_code}}
			    </c-detail-item>-->
			</c-detail>
			<c-table ref="productTable" :paging="false">
				<c-table-column
					label="颜色"
					width="120"
					name="product_color"
					v-if="systemConfig.system_size_enable=='ON'"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.product_color == data.product_color){
								span_count ++;
							}
						});
						return span_count;
					}"
				></c-table-column>
				
				<c-table-column
					label="颜色"
					width="100"
					name="product_spec"
					v-if="systemConfig.system_size_enable=='ON'"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.product_color == data.product_color && item.data.product_spec == data.product_spec){
								span_count ++;
							}
						});
						return span_count;
					}"
				></c-table-column>
				
				<c-table-column
					label="尺码"
					width="80"
					name="product_size"
					v-if="systemConfig.system_size_enable=='ON'"
				></c-table-column>
				
				<c-table-column
					label="任务数"
					width="70"
					name="product_count"
				></c-table-column>
				
				<c-table-column
					label="已报工"
					width="70"
					name="work_count"
				>
					<template v-slot="{data}">
						{{data.work_count}}<!--/{{data.qualified_count}} -->
					</template>
				</c-table-column>
				
				<c-table-column
					label="待报工"
					width="70"
				>
					<template v-slot="{data}">
						{{data.product_count-data.work_count}}
					</template>
				</c-table-column>
				
				<c-table-column
					label="本次报工"
					width="80"
				>
					<template v-slot="{data}">
						<c-input v-model="data.in_work_count" type="number" :range="[0,data.product_count-data.work_count]"></c-input>
					</template>
				</c-table-column>
			</c-table>
		</c-dialog>
		
		<!--详情 弹框-->
		<c-dialog ref="workDetail" :title="'任务：'+this.task_number+' - 详情'" width="800" >
			<c-detail column="2">
				<c-detail-item label="关联订单号">
				    {{detailInfo.order_number}}
				</c-detail-item>
				<c-detail-item label="派单时间">
				    {{detailInfo.create_date}}
				</c-detail-item>
				<c-detail-item v-if="detailInfo.customer_name" label="下单客户">
					{{detailInfo.customer_name}}
				</c-detail-item>
				<c-detail-item v-if="detailInfo.customer_name" label="客户货号">
				     {{detailInfo.customer_product_code}}
				</c-detail-item>
				<c-detail-item label="任务状态">
					<span v-if="detailInfo.state == 1" style="color:blue">待完成</span>
					<span v-if="detailInfo.state == 2" style="color:green">已完成</span>
					<span v-if="detailInfo.state == 3" style="color:#666">已取消</span>
				</c-detail-item>
				<c-detail-item label="派工状态">
					<span v-if="detailInfo.work_count == 0" style="color:blue">待报工</span>
					<span v-if="detailInfo.work_count > 0 && detailInfo.work_count < detailInfo.product_count" style="color:brown">部分报工</span>
					<span v-if="detailInfo.work_count > 0 && detailInfo.work_count >= detailInfo.product_count" style="color:green">全部报工</span>
				</c-detail-item>
				<c-detail-item label="品牌名称">
				    {{detailInfo.brand_name}}
				</c-detail-item>
				<c-detail-item label="品牌LOGO">
					<c-file v-if="detailInfo.brand_logo" media :src="detailInfo.brand_logo" style="width:30px;height:30px;"></c-file>
				</c-detail-item>
			    <c-detail-item label="产品名称">
			         {{detailInfo.product_name}}
			    </c-detail-item>
<!--			    <c-detail-item label="产品编码">
			        {{detailInfo.product_code}}
			    </c-detail-item>-->
				<c-detail-item label="生产计划时间">
				    {{detailInfo.task_begin_date}}至{{detailInfo.task_end_date}}
				</c-detail-item>
				<c-detail-item label="交货日期">
				    {{detailInfo.schedule_date}}
				</c-detail-item>
				<c-detail-item label="订单生产要求">
					<template v-if="detailInfo.production_json" >
						<div v-for="(item, i) in JSON.parse(detailInfo.production_json)" :key="i">{{i}}：{{item}}</div>
					</template>
				</c-detail-item>
				<c-detail-item label="产品生产参数">
					<template v-if="detailInfo.product_json" >
						<div v-for="(item, i) in JSON.parse(detailInfo.product_json)" :key="i">{{i}}：{{item}}</div>
					</template>
				</c-detail-item>
				<c-detail-item label="产品生产说明" colspan="2">
				    {{detailInfo.product_remark}}
				</c-detail-item>
				<c-detail-item label="任务总数">
				    {{detailInfo.product_count}}
				</c-detail-item>
<!--				<c-detail-item label="班组">
				    {{detailInfo.team_name}}
				</c-detail-item>-->
				<c-detail-item label="负责人">
				    {{detailInfo.person_responsible_user_realname}}
				</c-detail-item>
<!--				<c-detail-item label="机台">
				    {{detailInfo.machine_name}}
				</c-detail-item>-->
				<c-detail-item label="质检">
				    {{detailInfo.quality_user_realname}}
				</c-detail-item>
				<c-detail-item label="派单人">
				    {{detailInfo.create_user_realname}}
				</c-detail-item>
				<c-detail-item label="任务完成时间" v-if="detailInfo.finish_date">
				    {{detailInfo.finish_date}}
				</c-detail-item>
			</c-detail>
		</c-dialog>
		<!--选择打印模板-->
		<template-selector ref="templateSelector"></template-selector>
  </page>
</template>

<script>
import {cryptoJs} from '@deanwu/vue-component-library'
import {mapState} from 'vuex'
import templateSelector from "@/components/service/print_template_selector.vue";

export default {
	name: 'm_production_task',
	components: {
		templateSelector
	},
	data() {
		return {
			task_number: '',
			product_name: '',
			product_code: '',
			editState: false,
			stateOptions: [{
				name: '待完成',
				value: 1
			}, {
				name: '已完成',
				value: 2
			}, {
				name: '已取消',
				value: 3
			}],
			detailInfo: {}
		}
	},
	computed: {
		...mapState(['sizeConfig','systemConfig'])
	},
	mounted() {
		this.$refs.search.search();
		this.$bus.$on('taskList.update', page => {
			this.$refs.table.update(page);
		});
	},
	methods: {
		//搜索
		searchHandle(data) {
			this.$refs.table.load({
				url: '/production/task/list',
				data
			});
		},
		//查看生产要求
		workDetail(data){
			this.request({
				url: '/production/task/detail',
				data: {
					id: data.id,
				},
				loading: true,
				success: (data) => {
					this.detailInfo = data
				}
			});
			this.$refs.workDetail.open();
		},
		//任务报工 - 弹框
		workTask(val) {
			this.task_number = val.task_number;
			this.task_id = val.id;
			this.product_name = val.product_name;
			this.product_code = val.product_code;
			this.$refs.productTable.load({
				url: '/production/task/product_list',
				data: {id:val.id},
				dataFormatter: data => {
					data.forEach(item => {
						item.in_work_count = item.product_count-item.work_count
					});
					return data;
				}
			});
			this.$refs.workTask.open();
		},
		//任务报工-确定
		submitWorkTask(stop, next){
			stop();
			var productData = this.$refs.productTable.getRow();
			var productList = [];
			var product_count = 0;
			if(productData.length > 0){
				productData.forEach(item => {
					var item_data = item.data;
					if(item_data.in_work_count > 0){
						productList.push({
							id: item_data.id,
							product_count: item_data.in_work_count||0
							
						})
						product_count = parseFloat(product_count) + parseFloat(item_data.in_work_count||0);
					}
				});
			}
			
			if(product_count == 0){
				this.$message({
					type: 'warn',
					message: '请至少输入一个报工数量'
				});
				return false;
			}
			
			this.request({
				url: '/production/work/add',
				data: {
					task_number: this.task_number,
					task_id: this.task_id,
					product_count: product_count,
					product_list: productList
				},
				loading: true,
				success: () => {
					this.$refs.table.update();
					this.$message({
						type: 'success',
						message: '报工成功'
					});
					next();
				}
			});
			
		},
		//删除任务
		delTask(val) {
			this.$confirm({
				title: '确认',
				message: '确定要删除任务【'+val.task_number+'】吗？',  // 提示内容,
				buttonName: '确定',  // 确定按钮文字
				cancelButton: true,  // 是否显示取消按钮
				resolve: () => {
					this.request({
						url: '/production/task/remove',
						data: {task_id:val.id},
						loading: true,
						success: data => {
							this.$message({
								message: '删除成功',
								type: 'success'
							});
							this.$refs.table.update();
						}
					});
				},  // 确定按钮回调
				cancel: () => {},  // 取消按钮回调
			});
		},
		//取消任务
		cancelTask(val){
			this.$confirm({
				title: '确认',
				message: '确定要取消任务【'+val.task_number+'】吗？',  // 提示内容,
				buttonName: '确定',  // 确定按钮文字
				cancelButton: true,  // 是否显示取消按钮
				resolve: () => {
					this.request({
						url: '/production/task/cancel',
						data: {id:val.id},
						loading: true,
						success: data => {
							this.$message({
							  message: '取消成功',
							  type: 'success'
							});
							this.$refs.table.update();
						}
					});
				},  // 确定按钮回调
				cancel: () => {},  // 取消按钮回调
			});
		},
		//完成任务
		finishTask(val){
			this.$confirm({
				title: '确认',
				message: '确定任务【'+val.task_number+'】已经完成了吗？',  // 提示内容,
				buttonName: '确定',  // 确定按钮文字
				cancelButton: true,  // 是否显示取消按钮
				resolve: () => {
					this.request({
						url: '/production/task/finish',
						data: {id:val.id},
						loading: true,
						success: data => {
							this.$message({
							  message: '操作成功',
							  type: 'success'
							});
							this.$refs.table.update();
						}
					});
				},  // 确定按钮回调
				cancel: () => {},  // 取消按钮回调
			});
		},
		//打印
		printTask(billtype,data) {
			this.getApp().lodopPrint({
				billtype_id: billtype,
				number: data.task_number
			});
		},
		//打开打印模板列表弹窗
		selectTemplate(billtype,billtype_name){
			this.$refs.templateSelector.open(billtype,billtype_name);
		}
  }
}
</script>