<template>
	<c-dialog ref="dialog" :title="title" width="850"  @resolve="resolve">
		<m-search ref="search" @search="searchHandle">
			<m-search-item label="模板名称">
				<c-input name="template_name"></c-input>
			</m-search-item>
			<m-search-item label="状态">
				<c-select name="state" :options="stateOption"></c-select>
			</m-search-item>
		</m-search>

		<m-operate>
			<c-button color="sys" @click="navigateTo(`/template_add/${billtype_id}`)">新建模板</c-button>
		</m-operate>
		<c-table ref="table" height="400">
			<c-table-column
				type="selector"
				width="15"
				:multiple="false"
			></c-table-column>
			<c-table-column
				label="模板名称"
				width="150"
				name="template_name"
			>
			</c-table-column>

			<c-table-column
				label="状态"
				width="90"
				name="state"
			>
				<template v-slot="{data}">
					 <span v-if="data.state == 2" style="color: green">启用</span>
					<c-switch v-if="data.state == 1" :value="data.state" :state-value="[2,1]" :state-text="['启用','禁用']"
				          @change="changeState"
				          @beforeChange="beforeChangeState(arguments[0], arguments[1], data)"
					></c-switch>
				</template>
			</c-table-column>

			<c-table-column
				label="备注"
				width="180"
				name="remark"
			>
			</c-table-column>
			<c-table-column
				label="创建时间"
				width="140"
				name="create_date"
			></c-table-column>

			<c-table-column
				type="button"
				label="操作"
				width="120"
			>
				<template v-slot="{data}">
					<c-table-button @click="navigateTo(`/template_edit/${data.id}`)">编辑</c-table-button>
					<c-table-button v-if="data.state == 1" @click="remove(data)">删除</c-table-button>
				</template>
			</c-table-column>
		</c-table>
	</c-dialog>
</template>

<script>
	export default {
		props: {
			multiple: Boolean
		},
		data() {
			return {
				billtype_id:0,
				title:'打印模板列表',
				stateOption: [{
					name: '禁用',
					value: "1"
				},{
					name: '启用',
					value: "2"
				}]
			}
		},

		methods: {
			searchHandle(data = {}) {
				data.billtype_id = this.billtype_id;
				this.$refs.table.load({
					url: '/print/template/list',
					data: data
				});
			},
			/**
			 * @param billtype_id     业务类型ID
			 * @param title           标题
			 */
			open(billtype_id,title) {
				this.billtype_id = billtype_id;
				this.title = "【"+title+"】打印模板列表";
				this.$refs.dialog.open();
				this.searchHandle();
			},
			
			resolve(stop) {
				var data = this.$refs.table.getRow('selected');
				if (!data.length) {
					stop();
					this.$message({
						message: '请选择打印模板'
					});
					return;
				}
				this.$emit('resolve', Array.from(data, item => item.data));
			},
			/***
			 * 启用禁用前 回调函数
			 */
			beforeChangeState(state,next,data){
				// 确认弹窗
				this.$confirm({
					title: (state?'禁用':'启用')+'模板',
					message: '是否确定【'+(state?'禁用':'启用')+'】模板【'+data.template_name+'】？',  // 提示内容,
					buttonName: '确定',  // 确定按钮文字
					cancelButton: true,  // 是否显示取消按钮
					resolve: () => {
						this.request({
							url: '/print/template/state',
							data: {
								template_id:data.id,
								state:state?1:2
							},
							loading: true,
							success: data => {
								//远程接口调用完成，执行next方法，变更开关状态（执行失败则不变更状态）
								next();
							}
						});
					},  // 确定按钮回调
					cancel: () => {},  // 取消按钮回调
				});

			},
			/***
			 * 启用禁用 完成回调函数
			 */
			changeState(state){
				// 消息弹窗
				this.$message({
					message: ((state==1)?'禁用':'启用')+'成功',  // 提示内容,
					type: 'success'
				});
				//刷新列表
				this.searchHandle();
			},
			//删除模板
			remove(data){
				this.$confirm({
					title: '确认',
					message: '确定要删除模板【'+data.template_name+'】吗？',  // 提示内容,
					buttonName: '确定',  // 确定按钮文字
					cancelButton: true,  // 是否显示取消按钮
					resolve: () => {
						this.request({
							url: '/print/template/remove',
							data: {template_id:data.id},
							loading: true,
							success: data => {
								this.$refs.table.update();
								this.$message({
									message: '删除成功',
									type: 'success'
								});
							}
						});
					},  // 确定按钮回调
					cancel: () => {},  // 取消按钮回调
				});
			},
		}
	}
</script>